<!--商品分类-->
<template>
    <div class="mainBox">
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 60px );overflow: auto;">
            <div class="block">
                <el-button type="text" icon="el-icon-plus" style="margin-left:5px" @click="dialogTypeVisible = true">
                    新建一级分类
                </el-button>

                <el-tree :data="list" node-key="id" default-expand-all :expand-on-click-node="false">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span style="font-weight: bold;">{{ data.cate_name }}</span>
                        <span>
                            <el-button type="text" size="small" v-if="data.pid == 0" @click="() => append(node, data)" style="margin-left:52px;color:#67C23A">
                                新建子分类
                            </el-button>
                            <el-button type="text" size="small" @click="() => remove(node, data)" v-if="data.pid!=0" style="margin-left:50px;color:#F56C6C">
                                删除分类
                            </el-button>
                            <el-button type="text" size="small" @click="() => edit(node,data)" style="margin-left:50px">
                                编辑分类
                            </el-button>
                        </span>
                    </span>
                </el-tree>
            </div>
        </div>

        <el-dialog :title="title" :visible.sync="dialogTypeVisible" :modal-append-to-body="false"  :close-on-click-modal="false" width="600px">
            <el-form ref="formAuth" :rules="formRules" :model="formData"  size="small" label-position="top" class="alertForm">
                <el-form-item label="分类名称" prop="cate_name" style="width:100%">
                    <el-input v-model="formData.cate_name" aucate_nametocomplete="off"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogTypeVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {shopTypeListApi,shopTypeEditApi,shopTypeDelApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                list:[], //商品列表

                formData:{
                    pid:0
                },
                formRules:{
                    cate_name: [{
                        required: true,
                        message: '请输入分类名称',
                        trigger: 'blur'
                    }],
                },

                title:'添加分类',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogTypeVisible:false, //弹窗状态
            }
        },
        // components:{
        //     uploadImage
        // },
        mounted() {
             this.getList();
        },
        methods: {
            //分类列表
            getList: function(){
                shopTypeListApi().then(response=>{
                    this.list = response;
                })
            },

            append(data) {
                this.$prompt('子类别名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /\S/,
                    inputErrorMessage: '不能为空'
                }).then(({
                    value
                }) => {
                    shopTypeEditApi({
                        id:0,
                        pid:data.data.id,
                        cate_name:value
                    }).then(()=>{
                        this.$message({
                            type: 'success',
                            message: '子类添加成功!'
                        });
                        this.getList();
                    })
                }).catch(() => {});
            },
            //编辑分类
            edit(node, data) {
                this.$prompt('类别名称', '提示', {
                    inputValue: data.cate_name
                }, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /\S/,
                    inputErrorMessage: '不能为空'
                }).then(({
                    value
                }) => {
                    shopTypeEditApi({
                        id: data.id,
                        cate_name: value,
                    }).then(()=>{
                        this.$message({
                            type: 'success',
                            message: '修改成功!'
                        });
                        this.getList();
                    })
                    
                }).catch(() => {});
            },
            //删除分类
            remove(node, data) {
                this.$confirm(`确定删除分类'${data.cate_name}'吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    shopTypeDelApi({
                        id:data.id
                    }).then(response=>{
                        if(response == true){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }else{
                            this.$message({
                                type: 'error',
                                message: '该分类下面存在商品，不允许删除!'
                            });
                        }
                        
                        this.getList();
                    })
                })
            },
            submit() {
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        shopTypeEditApi({
                            id: 0,
                            pid: 0,
                            cate_name: this.formData.cate_name,
                        }).then(()=>{
                            this.dialogTypeVisible = false;
                            this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                            this.getList();
                        })
                    }
                })
            },
        }
    }
</script>
<style>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

    .el-tree-node.is-expanded>.el-tree-node__children span {
        padding-left: 7px;
        font-weight: normal !important;
    }
</style>